import * as React from "react";
import { graphql } from "gatsby";

import environment from "../config/environment";
import Layout from "../components/layout";
import Hero from "../components/hero";
import BlogIndexSection from "../components/blog-index-section";

export const ArchiveEbook = (props) => {
  const {
    data: {
      posts: { edges, pageInfo },
    },
    pageContext: { permalink },
  } = props;

  return (
    <Layout
      seo={{
        title: "Tutti i nostri Case Study - Pricyo",
        metaDesc:
          "Leggi i nostri Case Study ed impara da essi le strategie da usare per il tuo Ecommerce",
        canonical: `${environment.HOST}/${permalink}`,
        opengraphUrl: `/${permalink}`,
      }}
    >
      <div className="md:w-2/3 mx-auto">
        <Hero title="Tutti i nostri Ebook" subtitle=""></Hero>
        <BlogIndexSection
          title=""
          posts={edges.map((edge) => edge.node)}
        ></BlogIndexSection>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query archiveEbook($skip: Int!, $limit: Int!) {
    posts: allWpEbook(
      limit: $limit
      skip: $skip
      sort: { fields: [date], order: DESC }
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
      }
      edges {
        node {
          id
          title
          date
          uri
          categories {
            nodes {
              id
              name
              uri
            }
          }
          featuredImage {
            node {
              id
            }
          }
          author {
            node {
              id
              name
              uri
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default ArchiveEbook;
